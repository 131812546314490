import type { SkipCharge } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { getChargeType } from './getChargeType';
import { getSkipAmount } from './getSkipAmount';

export const setDefaultSkipJobCharge = async () => {
    let amount = ApplicationState.getSetting<SkipCharge | undefined>('global.skip-charge-default')?.amount || 0;
    const type = await getChargeType();
    if (!type) return;

    if (type === 'none') {
        ApplicationState.clearSetting('global.skip-charge-default');
        return;
    }

    const newAmount = await getSkipAmount(type, amount);
    if (newAmount === undefined) return;

    amount = newAmount;
    ApplicationState.setSetting<SkipCharge>('global.skip-charge-default', { amount, type });
};
