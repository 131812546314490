import type { JobOccurrence, StoredObject, TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { completeMetadata } from '../../ReactUI/CustomFields/CompleteCustomFields/dialog/completeMetadata';
import type { IScheduleItemAction } from './IScheduleItemAction';

export class CustomFieldAction implements IScheduleItemAction {
    occurrence: JobOccurrence;
    constructor(protected joboccurrence: JobOccurrence) {
        this.occurrence = joboccurrence;
    }
    actionType = <ItemActionType>'action-custom-fields';
    handler(): Promise<boolean> {
        return completeMetadata({
            targetObject: this.occurrence as StoredObject,
            type: 'job-occurrence-completion',
        });
    }
    tooltip: TranslationKey = 'general.custom-fields';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker'];
}
