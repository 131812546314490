import type { Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { AccountUser, notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Prompt } from '../Dialogs/Prompt';
import { Logger } from '../Logger';
import { Utilities } from '../Utilities';
import type { AssignmentSummary } from './Assignees/AssignmentService';
import { AssignmentService } from './Assignees/AssignmentService';
import { UserService } from './UserService';

type AssignmentSummaryListItem = AssignmentSummary & {
    selected: boolean;
};
export class SelectMultipleUsersOrTeamsDialog extends CustomDialog<Array<AccountUser | Team> | undefined> {
    public filteredList: Array<AssignmentSummaryListItem>;
    public searchTerm = '';
    private selectedUsersOrTeams: Array<AssignmentSummaryListItem> = [];

    constructor() {
        super('SelectMultipleUsersOrTeamsDialog', '../Users/SelectMultipleUsersOrTeamsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'select-list-dialog select-dialog-with-search no-nav-shadow',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this._init();
    }

    private _init() {
        this.load();
    }

    private _list: Array<AssignmentSummaryListItem> = [];
    public async load() {
        try {
            const teams = UserService.getTeams().filter(x => x.activeFrom === undefined && x.activeTo === undefined);
            const users = UserService.getWorkers();

            //if no default user, add unassigned
            const defaultAssignee = AssignmentService.getDefaultAssigneeUserOrTeam();

            if (!defaultAssignee) {
                const unassigned: AssignmentSummaryListItem = {
                    id: 'UNASSIGNED',
                    name: ApplicationState.localise('general.unassigned'),
                    type: 'unassigned',
                    skills: [],
                    description: 'Set selected jobs to unassigned',
                    selected: false,
                };

                this._list.push(unassigned);
            } else if (defaultAssignee) {
                const defaultAssigneeSummary: AssignmentSummaryListItem = {
                    id: 'UNASSIGNED',
                    name: `Default Assignee (${defaultAssignee.name})`,
                    type: 'unassigned',
                    description: 'Set selected jobs to the default assignee',
                    skills: [],
                    selected: false,
                };
                this._list.push(defaultAssigneeSummary);
            }
            if (ApplicationState.multiUserEnabled) {
                for (const team of teams) {
                    const teamSummary: AssignmentSummaryListItem = {
                        id: team._id,
                        name: team.name,
                        type: 'team',
                        description: 'Set selected jobs to this team',
                        skills: [],
                        selected: false,
                    };
                    this._list.push(teamSummary);
                }
            }
            for (const user of users) {
                const userSummary: AssignmentSummaryListItem = {
                    id: user._id,
                    name: user.name,
                    type: 'user',
                    description: 'Set selected jobs to this user',
                    skills: [],
                    selected: false,
                };
                this._list.push(userSummary);
            }

            this.filter();
        } catch (error) {
            Logger.error('Error in load() on SelectUserDialog', error);
        }
    }

    public filter() {
        this.filteredList = this._list.filter(userOrTeam => {
            return (
                !this.searchTerm ||
                (this.searchTerm && userOrTeam.name && userOrTeam.name.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1)
            );
        });
    }

    protected _delegateOk() {
        if (this.selectedUsersOrTeams.some(userOrTeam => userOrTeam.id === 'UNASSIGNED')) {
            const user = new AccountUser('');
            user._id = 'UNASSIGNED';
            user.name = ApplicationState.localise('general.unassigned');
            return this.ok([user]);
        }

        const assignees = this.selectedUsersOrTeams
            .map(userOrTeam => Data.get<AccountUser | Team>(userOrTeam.id))
            .filter(notNullUndefinedEmptyOrZero);
        this.ok(assignees);
    }

    protected selectUserOrTeam(user: AssignmentSummaryListItem) {
        if (user.id === 'UNASSIGNED') {
            if (!user.selected) {
                //Toggle On
                this.selectedUsersOrTeams.push(user);
                user.selected = true;

                for (const u of this.filteredList) if (u.id !== 'UNASSIGNED') u.selected = false;
            } else {
                //Toggle Off
                this.selectedUsersOrTeams = this.selectedUsersOrTeams.filter(u => u.id !== user.id);
                user.selected = false;
            }

            return;
        } else {
            const unassigned = this.filteredList.find(u => u.id === 'UNASSIGNED');
            if (unassigned) {
                unassigned.selected = false;
                this.selectedUsersOrTeams = this.selectedUsersOrTeams.filter(u => u.id !== unassigned.id);
            }
        }

        if (user.selected) {
            this.selectedUsersOrTeams = this.selectedUsersOrTeams.filter(u => u.id !== user.id);
            user.selected = false;
        } else {
            this.selectedUsersOrTeams.push(user);
            user.selected = true;
        }
    }

    public async getResult() {
        if (!this.selectedUsersOrTeams) return;

        if (this.selectedUsersOrTeams.some(user => user.id === 'UNASSIGNED')) {
            const user = new AccountUser('');
            user._id = 'UNASSIGNED';
            user.name = ApplicationState.localise('general.unassigned');
            return [user];
        }

        return this.selectedUsersOrTeams.map(userOrTeam => Data.get<AccountUser | Team>(userOrTeam.id)).filter(notNullUndefinedEmptyOrZero);
    }

    protected getColour(userOrTeam: AssignmentSummaryListItem) {
        return userOrTeam.type === 'unassigned' ? '#888' : `#${Utilities.hexFromString(userOrTeam.name || 'unknown')}`;
    }

    protected getIcon(userOrTeam: AssignmentSummaryListItem) {
        return userOrTeam.type === 'unassigned' ? 'remove' : userOrTeam.type === 'team' ? 'people' : 'person';
    }

    protected getLimitedSkills(userOrTeam: AssignmentSummaryListItem) {
        const skills = userOrTeam.skills || [];
        return skills.length > 3 ? skills.slice(0, 3) : skills;
    }

    protected async showSkills(userOrTeam: AssignmentSummaryListItem, event: Event) {
        event.stopPropagation();
        await new Prompt(
            `${userOrTeam.name}'s Skills` as TranslationKey,
            `${userOrTeam.skills?.map(s => s).join(', ')}` as TranslationKey,
            {
                cancelLabel: '',
            }
        ).show();
    }
}
