import { copyObject, wait } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { enterSkipReasonText } from './enterSkipReasonText';
import { getChargeType } from './getChargeType';
import { getSkipAmount } from './getSkipAmount';
import { getReasonText as getSkipReasonText } from './getSkipReasonText';

export const updateSkipReasonsAndCharges = async () => {
    for (;;) {
        const options: Array<{ text: string; value: string }> = [
            { text: ApplicationState.localise('settings.new-skip-reason'), value: '' },
        ];
        const reasons = copyObject(ApplicationState.skipReasons);
        for (const reason of reasons) {
            const text = getSkipReasonText(reason);
            options.push({ value: reason.name, text });
        }

        const select = new Select('settings.skip-reasons-and-charges-title', options, 'text', 'value');
        const selectedReason = await select.show();

        if (select.cancelled) return;

        let reason = reasons.find(x => x.name === selectedReason.value);

        const reasonTextUpdate = await enterSkipReasonText(reason);
        if (reasonTextUpdate.action === 'cancel') continue;

        if (reasonTextUpdate.action === 'delete') {
            const deleteName = reason?.name;
            if (!deleteName) continue;
            reasons.splice(
                reasons.findIndex(x => x.name === deleteName),
                1
            );
            ApplicationState.skipReasons = reasons;
            continue;
        }

        if (reason) reason.name = reasonTextUpdate.text;
        else {
            if (reasons.find(x => x.name.toLowerCase().trim() === reasonTextUpdate.text.toLowerCase().trim())) {
                await new Prompt('general.warning', 'settings.skip-reason-duplicate', { cancelLabel: '' }).show();
                continue;
            }
            reason = { name: reasonTextUpdate.text };
            reasons.push(reason);
        }

        if (ApplicationState.hasUltimateOrAbove) {
            const chargeType = await getChargeType();
            if (!chargeType) continue;

            if (chargeType === 'none') {
                delete reason.skipCharge;
                ApplicationState.skipReasons = reasons;
                continue;
            }

            const amount = await getSkipAmount(chargeType, reason.skipCharge?.amount);
            if (amount === undefined) continue;

            reason.skipCharge = amount === 0 ? undefined : { type: chargeType, amount };
        }

        ApplicationState.skipReasons = reasons;

        await wait();
    }
};
