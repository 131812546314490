import type { AccountUser, Team } from '@nexdynamic/squeegee-common';
import { notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';

import { CustomDialog } from '../Dialogs/CustomDialog';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { Logger } from '../Logger';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { UserService } from './UserService';
import { Workload } from './Workload';

export class SelectMultipleAssigneesDialog extends CustomDialog<Array<Workload> | undefined> {
    public filteredList: Array<Workload>;
    public searchTerm = '';

    protected total: number;

    constructor(
        private _scheduleItems: Array<ScheduleItem>,
        private _includeUnassigned = true,
        private _includeSpareCapacity = true,
        private _includeAll = false,
        private _hideEmpty = false
    ) {
        super('SelectMultipleAssigneesDialog', '../Users/SelectMultipleAssigneesDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'select-list-dialog select-dialog-with-search',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this._init();
        this.total = _scheduleItems.length;
    }

    protected _init() {
        this.load();
    }

    private _list: Array<Workload> = [];
    public async load() {
        try {
            const users = UserService.getWorkers();
            const allTeams = UserService.getTeams();

            const sortedScheduleItemsByIsoDueDateOrPlannedDate = this._scheduleItems.sort((a, b) => {
                const dateA = a.occurrence.isoPlannedDate || a.occurrence.isoDueDate;
                const dateB = b.occurrence.isoPlannedDate || b.occurrence.isoDueDate;
                return dateA?.localeCompare(dateB);
            });
            const oldestScheduleItemByIsoDueOrPlannedDate = sortedScheduleItemsByIsoDueDateOrPlannedDate[0];
            const newestScheduleItemByIsoDueOrPlannedDate =
                sortedScheduleItemsByIsoDueDateOrPlannedDate[sortedScheduleItemsByIsoDueDateOrPlannedDate.length - 1];

            const availableTeams = new Array<Team>();
            for (const team of allTeams) {
                if (!team.activeFrom && !team.activeTo) {
                    availableTeams.push(team);
                    continue;
                }
                if (
                    team.activeFrom &&
                    team.activeTo &&
                    team.activeFrom <=
                        (oldestScheduleItemByIsoDueOrPlannedDate.occurrence.isoPlannedDate ||
                            oldestScheduleItemByIsoDueOrPlannedDate.occurrence.isoDueDate) &&
                    team.activeTo >=
                        (newestScheduleItemByIsoDueOrPlannedDate.occurrence.isoPlannedDate ||
                            newestScheduleItemByIsoDueOrPlannedDate.occurrence.isoDueDate)
                ) {
                    availableTeams.push(team);
                }
            }

            this._list = Workload.getWorkloadsForUsers(
                users,
                availableTeams,
                this._scheduleItems,
                this._includeUnassigned,
                this._includeSpareCapacity,
                this._includeAll,
                this._hideEmpty
            );

            this.filter();

            new ViewResizeEvent();
        } catch (error) {
            Logger.error('Error loading users or teams', error);
        }
    }

    protected _delegateOk() {
        const selectedWorkloads = this.filteredList.filter(workload => workload.selected);
        this.ok(selectedWorkloads.filter(notNullUndefinedEmptyOrZero));
    }

    public filter() {
        this.filteredList = this._list.filter(item => {
            if (!this.searchTerm) return true;

            const searchTerm = this.searchTerm.trim().toLowerCase();
            if (item.userOrTeam) {
                const email = (<AccountUser>item.userOrTeam).email && (<AccountUser>item.userOrTeam).email;
                if (email && email.toLowerCase().indexOf(searchTerm) > -1) return true;

                if (item.userOrTeam.name && item.userOrTeam.name.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1)
                    return true;
            }

            return false;
        });
    }

    protected selectAssignee(assignee: Workload) {
        if (assignee.userOrTeam?._id.toLowerCase() === 'unassigned') {
            if (!assignee.selected) {
                this._list.forEach(workload => {
                    if (workload.userOrTeam?._id.toLowerCase() !== 'unassigned') {
                        workload.selected = false;
                    }
                });
            }
        } else {
            const defaultUser = this._list.find(workload => workload.userOrTeam?._id.toLowerCase() === 'unassigned');
            if (defaultUser) {
                defaultUser.selected = false;
            }
        }
        assignee.selected = !assignee.selected;

        this.filteredList = [...this.filteredList];

        console.log(this._list);
    }

    public async getResult() {
        return this._list.filter(workload => workload.selected).filter(notNullUndefinedEmptyOrZero);
    }
}
